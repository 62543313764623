import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import FlavorSelect from "./flavor_select";
import Rating from "./rating";
import ImageGallery from "./image_gallery";
import AddToBag from "./add_to_bag";
import { get_items } from "../api";
import defaultImage from "../resource/defaultdrink.jpeg";
import InfoTabs from "./info_tabs";

function ItemSelector(props) {
  const defaultImages = props.defaultImages;
  const [items, setItems] = React.useState([]);
  const [selected, setSelected] = React.useState(null);
  const handleChange = (e) => {
    setSelected(e);
  };
  const { data, status } = useQuery(
    ["items", props.item_type],
    () => get_items(props.item_type),
    {
      onSuccess: (data) => {
        setItems(data.data);
      },
    }
  );
  return (
    <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
      <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
        {status === "success" && (
          <ImageGallery
            selected={selected}
            items={data.data}
            defaultImages={defaultImages}
            defaultImage={defaultImage}
          />
        )}
        <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            Energy Drink
          </h1>

          <div className="mt-3">
            <h2 className="sr-only">Product information</h2>
            <p className="text-3xl tracking-tight text-gray-900">
              ${selected ? selected.item_price / 100 : 3.65}
            </p>
          </div>

          <div className="mt-6">
            <h3 className="sr-only">Description</h3>

            <div
              className="space-y-6 text-base text-gray-700"
              dangerouslySetInnerHTML={{ __html: selected?.item_description }}
            />
          </div>

          <form className="mt-6">
            {status === "success" && (
              <FlavorSelect
                flavorGroups={items}
                selectedColor={selected}
                onChange={handleChange}
              />
            )}
            <AddToBag selected={selected} setSelected={setSelected} />
          </form>
          <div className="mt-2">
            <InfoTabs />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemSelector;
