function Faq() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              Frequently asked questions
            </h2>
            <p className="mt-4 text-base leading-7 text-gray-600">
              Can’t find the answer you’re looking for? Reach out to our{" "}
              <a
                href="#"
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                customer support
              </a>{" "}
              team.
            </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
const faqs = [
  {
    question: "When do the drinks arrive?",
    answer:
      "Depending when you place your original subscription order, your subscription will be put in 1 of 2 groups. We send out subscriptions on the 10th and the 25th of each month. This allows a consistent schedule for shipping and allows 5 days for your order to arrive by the 1st or 15th!",
  },
  {
    question: "When and how does my cart get charged?",
    answer:
      "Our card processor will charge your card on a reoccuring payment cycle (5) days before shipping. All cards will be charged on the 5th or 20th of each month, or the next available business day, depending on your subscription cycle.",
  },
  {
    question: "What if I want to change the drinks I get?",
    answer:
      "You can update your order at any time using our unique order login system, or let us choose for you, so you always have something different.",
  },
];
export default Faq;
