import Team from "./team";
import Content from "./content";

function About() {
  return (
    <div>
      <div className="bg-white px-6 py-24 sm:py-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <p className="text-base/7 font-semibold text-blue">Learn more</p>
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            About Us
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Learn more about how Big Blue Energy came to be and the team behind
            it.
          </p>
        </div>
      </div>
      <Content />
      <Team />

      <div className="bg-white px-6 py-24 sm:py-16 lg:px-2">
        <div className="mx-auto max-w-6xl text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Mission Statement
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            At Big Blue Energy Drinks, we believe in empowering individuals to
            achieve their highest potential. Our mission is to fuel your
            passion, drive and ambition with our premium, great-tasting drink
            formulations. Our drinks were created to deliver a powerful energy
            boost, without the crash or jitters that come with other energy
            drinks. We are committed to providing you with the ultimate energy
            experience in all aspects of your life. Join the Big Blue Energy
            Drinks movement and experience the difference for yourself!
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
