import * as React from "react";
import ItemSelector from "./item_selector";
import ShopCategories from "../product_categories/product_categories";
import Reviews from "./reviews";
import DrinkFeatures from "./product_features/drink_features";
import default1 from "../resource/BigBlueEnergy_NEW.jpg";
import default2 from "../resource/gabby_drinks.jpg";
function DrinkProductPage(props) {
  return (
    <div className="bg-white">
      <ItemSelector
        defaultImages={[default1, default2]}
        item_type={mapIdToKey(props.type)}
      />
      <ShopCategories />
      <DrinkFeatures />
      <div className="">
        <Reviews />
      </div>
    </div>
  );
}
function mapIdToKey(id) {
  if (id === "0") return "ENERGY";
  if (id === "1") return "LYTE";
  if (id === "2") return "RELOAD";
}
export default DrinkProductPage;
