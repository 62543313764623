import * as React from "react";
import { StarIcon } from "@heroicons/react/20/solid";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import BaseDialog from "../common/base_dialog";
import { get_reviews, add_review } from "../api";
import cn from "../utils/cn";
function Reviews() {
  const [open, setOpen] = React.useState(false);

  const { data, status } = useQuery(["reviews"], () => get_reviews());
  return (
    <div className="bg-white">
      <BaseDialog open={open} setOpen={setOpen}>
        <AddReview setOpen={setOpen} />
      </BaseDialog>
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="flex justify-between">
          <h2 className="text-lg font-medium text-gray-900">Recent reviews</h2>
          <button
            onClick={() => setOpen(true)}
            className="rounded-full bg-indigo-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add Review
          </button>
        </div>
        <div className="mt-6 space-y-10 divide-y divide-gray-200 border-b border-t border-gray-200 pb-10">
          {data?.map((review) => (
            <div
              key={review.review_id}
              className="pt-10 lg:grid lg:grid-cols-12 lg:gap-x-8"
            >
              <div className="lg:col-span-8 lg:col-start-5 xl:col-span-9 xl:col-start-4 xl:grid xl:grid-cols-3 xl:items-start xl:gap-x-8">
                <div className="flex items-center xl:col-span-1">
                  <div className="flex items-center">
                    {[0, 1, 2, 3, 4].map((rating) => (
                      <StarIcon
                        key={rating}
                        className={cn(
                          review.star_count > rating
                            ? "text-yellow-400"
                            : "text-gray-200",
                          "h-5 w-5 flex-shrink-0"
                        )}
                        aria-hidden="true"
                      />
                    ))}
                  </div>
                  <p className="ml-3 text-sm text-gray-700">
                    {review.star_count}
                    <span className="sr-only"> out of 5 stars</span>
                  </p>
                </div>

                <div className="mt-4 lg:mt-6 xl:col-span-2 xl:mt-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    {review.title}
                  </h3>

                  <div
                    className="mt-3 space-y-6 text-sm text-gray-500"
                    dangerouslySetInnerHTML={{ __html: review.message }}
                  />
                </div>
              </div>

              <div className="mt-6 flex items-center text-sm lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:mt-0 lg:flex-col lg:items-start xl:col-span-3">
                <p className="font-medium text-gray-900">{review.name}</p>
                <time
                  dateTime={review.created_date}
                  className="ml-4 border-l border-gray-200 pl-4 text-gray-500 lg:ml-0 lg:mt-2 lg:border-0 lg:pl-0"
                >
                  {review.date}
                </time>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
function AddReview(props) {
  const [state, setState] = React.useState({
    name: "",
    star_count: 5,
    message: "",
  });
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const handleCancel = () => {
    setState({});
    if (props.setOpen) {
      props.setOpen(false);
    }
  };
  const submitMutation = useMutation({
    mutationFn: async (review) => {
      await add_review(review);
    },
    onSettled: () => {
      handleCancel();
    },
  });
  const handleSubmit = () => {
    submitMutation.mutate(state);
  };
  return (
    <>
      <div>
        <label htmlFor="name" className="sr-only">
          Name
        </label>
        <input
          type="text"
          name="name"
          value={state.name}
          onChange={handleChange}
          id="name"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Name"
        />
      </div>
      <div>
        <label htmlFor="name" className="sr-only">
          Title
        </label>
        <input
          type="text"
          name="title"
          value={state.title}
          onChange={handleChange}
          id="title"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Title"
        />
      </div>
      <div className="flex items-center w-100">
        {[1, 2, 3, 4, 5].map((x) => (
          <StarIcon
            onClick={() => {
              setState({
                ...state,
                star_count: x,
              });
            }}
            className={cn(
              "w-6 h-6",
              state.star_count >= x
                ? "text-yellow-400 hover:text-yellow-300"
                : "text-gray-200 hover:text-slate-300"
            )}
          />
        ))}
      </div>
      <div>
        <label
          htmlFor="review"
          className="block text-sm font-medium leading-6 text-gray-900"
        ></label>
        <div className="mt-2">
          <textarea
            rows={4}
            name="message"
            value={state.message}
            onChange={handleChange}
            id="review"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            defaultValue={""}
          />
        </div>
        <button
          onClick={handleCancel}
          className="rounded-full bg-slate-400 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-400"
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="rounded-full bg-indigo-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </>
  );
}
export default Reviews;
