import { useState } from "react";
import { Radio, RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const tierList = [
  {
    id: 1,
    title: "The Casual",
    description: "For those that enjoy Big Blue Energy",
    drinks: "15 drinks",
  },
  {
    id: 2,
    title: "The Connissuer",
    description: "One for every day of the month",
    drinks: "30 drinks",
  },
  {
    id: 3,
    title: "All-In",
    description: "No such thing as too much Big Blue",
    drinks: "60 drinks",
  },
];

function CardRadio() {
  const [selectedTier, setSelectedTier] = useState(tierList[0]);

  return (
    <fieldset className="mx-8">
      <legend className="text-sm font-semibold leading-6 text-gray-900">
        Select tier
      </legend>
      <RadioGroup
        value={selectedTier}
        onChange={setSelectedTier}
        className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-rows-3 lg:grid-cols-3 sm:gap-x-4"
      >
        {tierList.map((tier) => (
          <Radio
            key={tier.id}
            value={tier}
            aria-label={tier.title}
            className="group relative flex cursor-pointer rounded-lg border border-gray-300 bg-white p-4 shadow-sm focus:outline-none data-[focus]:border-indigo-600 data-[focus]:ring-2 data-[focus]:ring-indigo-600"
          >
            <span className="flex flex-1">
              <span className="flex flex-col">
                <span className="block text-sm font-medium text-gray-900">
                  {tier.title}
                </span>
                <span className="mt-1 flex items-center text-sm text-gray-500">
                  {tier.description}
                </span>
                <span className="mt-6 text-sm font-medium text-gray-900">
                  {tier.drinks}
                </span>
              </span>
            </span>
            <CheckCircleIcon
              aria-hidden="true"
              className="h-5 w-5 text-indigo-600 [.group:not([data-checked])_&]:invisible"
            />
            <span
              aria-hidden="true"
              className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-indigo-600"
            />
          </Radio>
        ))}
      </RadioGroup>
    </fieldset>
  );
}
export default CardRadio;
