import * as React from "react";
import ImageCarousel from "./image_carousel";
import Typewriter from "./typewriter";
import ShopCategories from "./shop_categories";
import Testimonials from "./testimonials";
import SubscriptionCta from "./subscription_cta";
import WhyBigBlue from "./whybigblue";

function Home() {
  return (
    <div className="">
      <ImageCarousel />
      <ShopCategories />
      <WhyBigBlue/>
      <SubscriptionCta />
      <Testimonials />
      <Typewriter />
    </div>
  );
}
export default Home;
