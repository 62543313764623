import Spotlight from "./spotlight";
import Testimonial from "./testimonial";
import Tiers from "./tiers";
import Faq from "./faq";
import Cta from "./cta";
import Tutorial from "./tutorial";
function Subscribe() {
  return (
    <div>
      <Spotlight />
      <Tiers />
      <Tutorial />
      <Faq />
      <Testimonial />
      <Cta />
    </div>
  );
}

export default Subscribe;
