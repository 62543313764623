import * as React from "react";
import Toaster from "../common/toaster";
import Loading from "../common/loading";
import OrderInformation from "./order_information";
import OrderSummary from "./order_summary";
import NoItems from "./no_items";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  get_promo_code,
  get_checkout,
  post_order_information,
  clear_order,
} from "../api";

function Checkout() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [promo, setPromo] = React.useState("");
  const [promoModel, setPromoModel] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [state, setState] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    city: "",
    state: "",
    zip_code: "",
    address_1: "",
    address_2: "",
    discount_code: "",
    send_cups: true,
  });
  const [card, setCard] = React.useState({
    cardNumber: "",
    expirationDate: "",
    cardCode: "",
  });
  const handleChange = (e) => {
    if (
      e.target.name === "cardNumber" ||
      e.target.name === "expirationDate" ||
      e.target.name === "cardCode"
    ) {
      setCard({
        ...card,
        [e.target.name]: e.target.value,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }
  };
  const submitMutation = useMutation({
    mutationFn: async (order) => {
      setLoading(true);
      await post_order_information(order);
    },
    onError: () => {
      setShowError(true);
    },
    onSuccess: () => {
      clear_order();
      navigate("/order_confirmation");
    },
    onSettled: () => {
      setLoading(false);
    },
  });
  const handleSubmit = () => {
    submitMutation.mutate({
      order_information: state,
      credit_card: card,
    });
  };
  const { data, status } = useQuery(["checkout", promoModel], () =>
    get_checkout(promo)
  );
  const handlePromoCodeApply = async () => {
    setPromoModel("");
    const resp = await get_promo_code(promo);
    setPromoModel(resp.data);
    await queryClient.invalidateQueries({
      queryKey: ["checkout"],
    });
  };

  return (
    <div className="bg-white relative">
      <Toaster
        type="error"
        text="Error checking out"
        show={showError}
        setShow={setShowError}
      />
      <Loading loading={loading} />
      {data?.data.items.length === 0 ? (
        <NoItems />
      ) : (
        <main className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48">
          <h1 className="sr-only">Order information</h1>
          <OrderSummary
            checkoutModel={data?.data ?? {}}
            promo={promo}
            onPromoChange={(e) => setPromo(e.target.value)}
            items={data?.data?.items ?? []}
            handlePromoCodeApply={handlePromoCodeApply}
          />
          <OrderInformation
            state={state}
            card={card}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        </main>
      )}
      <div className="w-full flex justify-center">
        <a href="https://www.authorize.net/">
          <img
            src="https://www.authorize.net/content/dam/anet-redesign/reseller/authorizenet-200x50.png"
            border="0"
            alt="Authorize.net Logo"
            width="200"
            height="50"
          />
        </a>
      </div>
    </div>
  );
}
export default Checkout;
