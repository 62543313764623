import { TypewriterEffectSmooth } from "../components/ui/typewriter-effect";
import { PlaceholdersAndVanishInput } from "../components/ui/placeholders-and-vanish-input";
import * as React from "react";
import { joinMailingList } from "../api";
function Typewriter() {
  const [state, setState] = React.useState("");
  const handleChange = (e) => {
    setState(e.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    joinMailingList(state);
  };
  return (
    <div className="h-96 relative w-full overflow-hidden bg-slate-900 flex flex-col items-center justify-center rounded-lg">
      <div className="absolute inset-0 w-full h-full bg-slate-900 z-20 [mask-image:radial-gradient(transparent,white)] pointer-events-none" />

      <div className=" flex flex-col items-center justify-center h-[40rem]  ">
        <h3 className="text-white text-sm ">
          <b>ENERGY + FLAVOR + AWESOMENESS</b>
        </h3>
        <TypewriterEffectSmooth words={words} />
        <div className="mb-6 z-50 ">
          <PlaceholdersAndVanishInput
            placeholders={placeholders}
            onChange={handleChange}
            value={state}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  );
}
const placeholders = ["Join our email list"];
const words = [
  {
    text: "Experience",
  },
  {
    text: "the",
  },
  {
    text: "Big",
  },
  {
    text: "Blue",
  },
  {
    text: "Energy",
  },
  {
    text: "difference",
  },
  {
    text: "with",
  },
  {
    text: "every",
  },
  {
    text: "sip!",
  },
];
export default Typewriter;
