import * as React from "react";
import { CheckCircleIcon, TrashIcon } from "@heroicons/react/20/solid";
import drink from "../resource/pro/BBE_0724-1.jpg";

function FlavorSelect() {
  const [state, setState] = React.useState(new Map());
  const handleChange = (id, n) => {
    setState(new Map(state).set(id, n));
  };
  return (
    <div className="px-8 flex gap-4">
      <div className="basis-3/4">
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-6">
          {items.map((item) => (
            <li
              key={item.item_id}
              className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
            >
              <div className="flex w-full items-center justify-between space-x-6 rounded-lg">
                <FlavorCard
                  item={item}
                  handleChange={handleChange}
                  count={state.get(item.item_id)}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="basis-1/4">
        <Summary items={state} handleChange={handleChange} />
      </div>
    </div>
  );
}
function Summary(props) {
  const handleRemove = (id) => {
    if (props.handleChange) {
      props.handleChange(id, 0);
    }
  };
  const [num, setNum] = React.useState(0);
  React.useEffect(() => {
    const n = Array.from(props.items)
      .map(([_key, value]) => value)
      .reduce((partialSum, a) => partialSum + a, 0);
    setNum(n);
  }, [props.items, setNum]);
  console.log(props.items);
  return (
    <div>
      <div className=" rounded-lg border border-gray-200 bg-white shadow-sm">
        <h3 className="sr-only">Items in your cart</h3>
        <ul className="divide-y divide-gray-200">
          {items
            .filter((x) => props.items.has(x.item_id))
            .filter((x) => props.items.get(x.item_id) > 0)
            .map((x) => [x, props.items.get(x.item_id)])
            ?.map(([item, count]) => (
              <li key={item.item_id} className="flex px-4 py-6 sm:px-6">
                <div className="flex-shrink-0">
                  <img
                    alt={item.imageAlt}
                    src={drink}
                    className="w-20 rounded-md"
                  />
                </div>

                <div className="ml-6 flex flex-1 flex-col">
                  <div className="flex">
                    <div className="min-w-0 flex-1">
                      <h4 className="text-sm">
                        <a
                          href={item.href}
                          className="font-medium text-gray-700 hover:text-gray-800"
                        >
                          {item.item_name}
                        </a>
                      </h4>
                    </div>

                    <div className="ml-4 flow-root flex-shrink-0">
                      <button
                        onClick={() => handleRemove(item.item_id)}
                        type="button"
                        className="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500"
                      >
                        <span className="sr-only">Remove</span>
                        <TrashIcon aria-hidden="true" className="h-5 w-5" />
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-1 items-end justify-between pt-2">
                    <p className="mt-1 text-sm font-medium text-gray-900">
                      {item.price}
                    </p>

                    <div className="ml-4">
                      <label htmlFor="quantity" className="sr-only">
                        Quantity
                      </label>
                      <select
                        id={`quantity-${item.item_id}`}
                        name="quantity"
                        value={count}
                        readOnly
                        className="rounded-md border border-gray-300 text-left text-base font-medium text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                      </select>
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
        <dl className="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
          <div className="flex items-center justify-between">
            <dt className="text-sm">Drinks Selected</dt>
            <dd className="text-sm font-medium text-gray-900">{num}</dd>
          </div>
          <div className="flex items-center justify-between border-t border-gray-200 pt-6">
            <dt className="text-base font-medium">Total Drinks</dt>
            <dd className="text-base font-medium text-gray-900">25</dd>
          </div>
        </dl>

        <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
          <button
            type="button"
            className="w-full rounded-md border border-transparent bg-blue px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
          >
            Confirm order
          </button>
          <button
            type="button"
            className="w-full rounded-md border border-transparent bg-red-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-50 mt-2"
          >
            Clear All
          </button>
        </div>
      </div>
    </div>
  );
}
function FlavorCard(props) {
  const handleChange = (e) => {
    if (e.target.id === "clear") {
      if (props.handleChange) {
        props.handleChange(props.item.item_id, props.count);
      }
    } else {
      if (props.handleChange) {
        props.handleChange(props.item.item_id, (props.count ?? 0) + 1);
      }
    }
  };
  return (
    <div className="relative">
      <div onClick={handleChange} className="">
        <img src={drink} alt="Big Blue Drinks" className="rounded-t-lg" />
        <div className="px-4 pb-8 pt-2 ">{props.item.item_name}</div>
      </div>
      {props.count > 0 && (
        <div className="absolute bottom-2  right-5 flex justify-between items-center">
          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 me-2">
            {props.count} x
          </span>
          <span
            id="clear"
            onClick={handleChange}
            className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20"
          >
            Clear
          </span>
        </div>
      )}
    </div>
  );
}
const items = [
  {
    item_id: 1,
    item_name: "Blue Raspberry",
    item_slogan: "tastes just like candy",
    item_price: 365,
    image_logo_id: 1,
    image_id: 2,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 2,
    item_name: "Cherry Watermelon",
    item_slogan: "For those that love a bold flavor",
    item_price: 365,
    image_logo_id: 3,
    image_id: 4,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 4,
    item_name: "Lemon Lime",
    item_slogan: "Classic Refreshing Flavor",
    item_price: 365,
    image_logo_id: 7,
    image_id: 8,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 5,
    item_name: "Blue Green Apple",
    item_slogan: "Picture Green Apple but with Blue",
    item_price: 365,
    image_logo_id: 9,
    image_id: 10,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 6,
    item_name: "Deep Blue Sea",
    item_slogan: "A Berry Coconut Taste",
    item_price: 365,
    image_logo_id: 11,
    image_id: 12,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 7,
    item_name: "Watermelon Strawberry",
    item_slogan: "Candy Strawberry Delight",
    item_price: 365,
    image_logo_id: 13,
    image_id: 14,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 8,
    item_name: "Strawberry Pineapple",
    item_slogan: "The Original Recipe",
    item_price: 365,
    image_logo_id: 15,
    image_id: 16,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 9,
    item_name: "Peach Mango",
    item_slogan: "A Little Peachy.. A Little Mangoy",
    item_price: 365,
    image_logo_id: 17,
    image_id: 18,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 10,
    item_name: "Princess Peach",
    item_slogan: "Peach With A Bit Of Sass",
    item_price: 365,
    image_logo_id: 19,
    image_id: 20,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 11,
    item_name: "Orange Pineapple",
    item_slogan: "Perfect summer drink",
    item_price: 365,
    image_logo_id: 21,
    image_id: 22,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 13,
    item_name: "The Big Dawg",
    item_slogan: "Taste It And Be A Big Dawg",
    item_price: 365,
    image_logo_id: 25,
    image_id: 26,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 14,
    item_name: "Sour Green Apple",
    item_slogan: "The name says it all",
    item_price: 365,
    image_logo_id: 27,
    image_id: 28,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 16,
    item_name: "The Pink Drink",
    item_slogan: "The perfect taste of pink",
    item_price: 365,
    image_logo_id: 31,
    image_id: 32,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 17,
    item_name: "Nova Punch",
    item_slogan: "The Tropical Fruit Punch",
    item_price: 365,
    image_logo_id: 33,
    image_id: 34,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 18,
    item_name: "Cherry Limeade",
    item_slogan: "A Sweet Cherry Taste",
    item_price: 365,
    image_logo_id: 35,
    image_id: 36,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 20,
    item_name: "Blackberry Lemonade",
    item_slogan: "A Berry Lemonade Twist Up",
    item_price: 365,
    image_logo_id: 39,
    image_id: 40,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 21,
    item_name: "Blue Pineapple",
    item_slogan: "Blue + Yellow = Green",
    item_price: 365,
    image_logo_id: 41,
    image_id: 42,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 22,
    item_name: "Blue Raz Lemonade",
    item_slogan: "An exciting kick to lemonade",
    item_price: 365,
    image_logo_id: 43,
    image_id: 44,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 24,
    item_name: "Ice Mango",
    item_slogan: "A Blasting Mango Taste",
    item_price: 365,
    image_logo_id: 47,
    image_id: 48,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 19,
    item_name: "Tropical Orange",
    item_slogan: "The Ultimate Blast Of Orange",
    item_price: 365,
    image_logo_id: 37,
    image_id: 38,
    enable_status: 0,
    item_type: 0,
  },

  {
    item_id: 26,
    item_name: "Watermelon Pineapple",
    item_slogan: "The Coolest Flavor Collab Ever",
    item_price: 365,
    image_logo_id: 51,
    image_id: 52,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 25,
    item_name: "Orange Cherry",
    item_slogan: "Two Mellow, Fruity Flavors",
    item_price: 365,
    image_logo_id: 49,
    image_id: 50,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 27,
    item_name: "Blackberry Pineapple",
    item_slogan: "A Bold Sweet N Sour Berry Taste",
    item_price: 365,
    image_logo_id: 53,
    image_id: 54,
    enable_status: 0,
    item_type: 0,
  },
  {
    item_id: 3,
    item_name: "Grape Strawberry",
    item_slogan: "Two classic flavors... but together",
    item_price: 365,
    image_logo_id: 5,
    image_id: 6,
    enable_status: 1,
    item_type: 0,
  },
  {
    item_id: 23,
    item_name: "Grape Big League Chew",
    item_slogan: "Yeah it taste just like it",
    item_price: 365,
    image_logo_id: 45,
    image_id: 46,
    enable_status: 1,
    item_type: 0,
  },
];
export default FlavorSelect;
