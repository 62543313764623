import thomas from "../resource/thomas.jpg";
import bradan from "../resource/newfotos/bbheadshot.jpeg";
import jackson from "../resource/jackson.jpg";
import quinn from "../resource/quinn.jpg";

function Team() {
  return (
    <div className="bg-white py-24 md:py-32">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-5">
        <div className="max-w-2xl xl:col-span-2">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Meet the team!
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Big Blue Energy was started by a group of high school best friends
            that believed not only in eachother, but in the product they were
            creating. All aspects of Big Blue Energy are done in house. From the
            production of drink packets to the marketing/advertising and even
            this very website, everything is our own creation!
          </p>
        </div>
        <ul className="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
          {people.map((person) => (
            <li
              key={person.name}
              className="flex flex-col gap-10 pt-12 sm:flex-row"
            >
              <img
                className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
                src={person.imageUrl}
                alt=""
              />
              <div className="max-w-xl flex-auto">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">
                  {person.name}
                </h3>
                <p className="text-base leading-7 text-gray-600">
                  {person.role}
                </p>
                <p className="mt-6 text-base leading-7 text-gray-600">
                  {person.bio}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
const people = [
  {
    name: "Bradan Birt",
    role: "Owner/CEO",
    imageUrl: bradan,
    bio: "Bradan founded Big Blue Energy in 2022 with hopes of creating a product people could trust. He spent countless hours researching large drink brands and set out to make a drink company that was different for the rest. \"There are countless big brand drink companies that mass produce mediocre drink prodcuts without focusing on building a meaningful relationship with the consumer. I want to build a brand that cares about the individuals drinking my products. I hope to be a staple in drink shops all across the country someday and start cutting the big brands out of local communities!\"-Bradan",
  },
  {
    name: "Thomas McAndrew",
    role: "Web Developer / CTO",
    imageUrl: thomas,
    bio: "Thomas is the Chief Technology Officer for Big Blue Energy. He is also responsible for developing this very website, from complete scratch! Thomas graduated from University of Iowa State with a degree in computer science. He is a software developer for a worldwide company in Ames. Thomas currently lives in North Carolina and enjoys coding at the beach. Thomas has goals of making websites for businesses and expanding his presence in the tech world. Contact us for business inquiries!",
    xUrl: "#",
    linkedinUrl: "#",
  },
  {
    name: "Jackson Healey",
    role: "Sales/Creative Director",
    imageUrl: jackson,
    bio: "Enough can't be said about Jackson and his passion for Big Blue! No one is more committed to building the brand and speaking about Big Blue than him. Jackson teaches our next generation as a high school health and physical strength teacher. If you need any convincing to try Big Blue Energy, Jackson is the guy to talk to!",
    xUrl: "#",
    linkedinUrl: "#",
  },
  {
    name: "Quinn Mettert",
    role: "Sales/Market Analyst",
    imageUrl: quinn,
    bio: "Quinn is a key part in the growth of Big Blue in various areas of the business. Other than growing the brand and generating sales, Quinn is an associate data and saftey analyst for a leading midwest insurance firm. His knowledge of the market helps trememndously when it comes to generating sales!",
    xUrl: "#",
    linkedinUrl: "#",
  },

  // More people...
];
export default Team;
