import { Carousel } from "flowbite-react";
import img1 from "../resource/newfotos/banner18.jpg";
import img2 from "../resource/banners/2.jpg";
import img3 from "../resource/banners/3.jpg";
import img4 from "../resource/banners/4.jpg";
import img5 from "../resource/banners/5.jpg";

const images = [img1, img2, img3, img4, img5];
export default function ImageCarousel() {
  return (
    <div className="xl:h-[36rem] lg:h-96 md:h-80 h-52 w-full">
      <Carousel pauseOnHover>
        <img src={img1} alt="..." className="h-full cover" />
        <img src={img5} alt="..." className="h-full cover" />
        <img src={img3} alt="..." className="h-full cover" />
      </Carousel>
    </div>
  );
}
