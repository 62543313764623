import img1 from "../resource/newfotos/7.jpg";

export default function Example() {
  return (
    <div className="relative isolate bg-white pb-32 pt-0 sm:pt-8">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-50 blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 50%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 50% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="ml-[max(50%,38rem)] aspect-[1313/771] w-[62.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]"
        />
      </div>
      <div className="mx-auto max-w-7xl bg-indigo-600 lg:bg-transparent lg:px-8">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-10 lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:bg-transparent lg:py-16">
            <div
              aria-hidden="true"
              className="absolute inset-x-0 h-1/2 bg-white lg:hidden"
            />
            <div className="mx-auto max-w-md px-6 sm:max-w-3xl lg:max-w-none lg:p-0">
              <div className="aspect-h-6 aspect-w-10 sm:aspect-h-1 sm:aspect-w-2 lg:aspect-w-1">
                <img
                  alt=""
                  src={img1}
                  className="rounded-3xl object-cover object-center shadow-2xl"
                />
              </div>
            </div>
          </div>

          <div className="relative bg-white shadow-inner shadow-neutral-400 lg:col-span-10 lg:col-start-3 lg:row-start-1 lg:grid lg:grid-cols-10 lg:items-center lg:rounded-3xl">
            <div className="relative mx-auto max-w-md space-y-6 px-6 py-12 sm:max-w-3xl sm:py-16 lg:col-span-6 lg:col-start-4 lg:max-w-none lg:p-0">
              <h2
                id="join-heading"
                className="text-2xl font-bold tracking-tight text-black"
              >
                The Beginning
              </h2>
              <p className="text-base text-black">
                Big Bue Energy started in Decatur, Illinois in 2022. The first
                year of existence consisted only of in-store drink sales. That
                is until June of 2023, when Big Blue Drink packets came to be.
                Since then, Big Blue has made a prominent online presence,
                shipping its drinks across the entire country. Big Blue Drink
                Packets have opened lots of new opportunites. Fundraisers and
                licensed drink shops being the biggest. Big Blue Energy is
                officially sold in 10 different in-store and online locations.
              </p>
            </div>
            <div className="relative mx-auto max-w-md space-y-6 px-6 py-12 sm:max-w-3xl sm:py-16 lg:col-span-6 lg:col-start-4 lg:max-w-none lg:p-0">
              <h2
                id="join-heading"
                className="text-2xl font-bold tracking-tight text-black"
              >
                A Small Business Who Cares
              </h2>
              <p className="text-base text-black">
                Big Blue Energy is the first drink brand of its kind. Owned and
                operated as a small business, 100% of our ingredients are
                independently sourced. That means when you drink Big Blue, you
                aren't supporting a big business, you're supporting someone just
                like you. Read more below about how Big Blue came to be and
                those that helped get it started!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
