import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
function Faq() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p
                        dangerouslySetInnerHTML={{ __html: faq.answer }}
                        className="text-base leading-7 text-gray-600"
                      ></p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

const faqs = [
  {
    question: "Are Big Blue Energy Drinks really that awesome?",
    answer:
      "1000% YES! <br/> Big Blue Energy Drinks are the most flavorful, exciting energy drinks on the market. Loads of flavor, energy and excitement all in one cup! We will be one of the largest energy drink companies in the world someday!",
  },
  {
    question: "Are you partnered with any big companies? Herbalife? Lotus? Advocare?",
    answer:
      "Nope! Big Blue Energy is 100% independently sourced and we do not partner with any big companies to sell their product. Some people assume we sell Herbalife or Lotus because they look similar, but we do not in any way!",
  },
  {
    question: "How to make the drinks?",
    answer: `There are lots of ways to make Big Blue Energy Drinks!
                <br/><br/>
                <b>The Big Blue Way</b> (recommended):
                <br/>
                <b>1.</b> Add powder to Big Blue Energy Cup.
                <br/>
                <b>2.</b> Add 3-4oz of warm water and swirl in cup until dissolved.
                <br/>
                <b>3.</b> Fill cup to the top with ice. LOTS OF ICE! (warm water will melt some)
                <br/>
                (ice will melt throughout the day so don't fear you added too much)
                <br/>
                <b>4.</b> Add cold water to the top of the cup.
                <br/>
                <b>5.</b> Put on lid tight and spin the cup until the drink is fully dispersed. ENJOY!
                <br/>
                <br/>

                <b>Other ways:</b>
                <br/>
                Some people add powder to 16-32oz shaker bottles with ice or no ice and shake it up. There is still plenty of flavor even without ice!
                <br/>
                <br/>
                
                People have used their favorite 32oz tumbler with various amounts of ice and water to their liking. Remember, more ice doesn't mean less drink, just more flavor!
                
                <br/>
                <br/>
                Other people have even found success adding the whole packet to a large cup of ice water and stirring with a spoon. Just stir until the powder is all gone. It can be harder to dissolve it in ice cold water!`,
  },
  {
    question: "Who can consume/handle the energy drinks?",
    answer:
      "We recommend young children and pregnant women do not drink any form of energy drinks due to the caffeine levels. Ask a doctor if you're allowed.",
  },
  {
    question: "Are Big Blue Energy Drinks homemade?",
    answer:
      "Yes they are! Big Blue Energy is completely made to order. We have custom product made from our suppliers that we help design to our liking. Then it is shipped to our business where we prepare drinks in shop or in packet form for our online customers! Every time you are, your drinks are made and packaged fresh!",
  },
  {
    question: "Is there Taurine in the drinks?",
    answer:
      "Yes, every Big Blue Energy drink contains Taurine. This amino-acid is found naturally in the body, as well as in meat, fish and other natural products. This has been known to enhance athletic performance. Athletes please be advised.",
  },
  {
    question: "Are the energy drinks ZERO sugar? Artificial Sweeteners?",
    answer: `Big Blue Energy Drinks contains zero sugar, which is part of what makes them so awesome! The drinks do contain an assortment of non-nutritive (artificial) sweeteners. Non-nutritive sweeteners are some of the most studied food products in the entire world and are 100% safe for human consumption, especially in the small amount we use them. This helps with the flavorful taste!
<br/>
        Those with the rare hereditary disease PKU, be advised before consuming.`,
  },
  {
    question: "Are the packets homemade? No air?",
    answer: `Yes! Every packet is made by hand from the label, to filling the powder and even sealing them. Big Blue Energy packets have little to no air in them to make them last longer. Don’t worry, even if they feel stiff, they will still open and pour out the same way!`,
  },
  {
    question: "How long does it take to package and ship? Shipping Problems?",
    answer: `
All orders are made and sent out within 24-48hrs after placing the order. We use standard USPS shipping that typically only takes 2-3 days to receive. Shipping times can vary by a day depending on weekends, holidays and what time of day the order is placed!
If you have any issues with your package, please message us in the CONTACT US tab with your contact information and we will contact you as soon as possible!
      `,
  },
];

export default Faq;
