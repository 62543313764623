import { RadioGroup, Radio, Label } from "@headlessui/react";
import cn from "../utils/cn";

/*
 * flavorGroups: Array {
 *      groupName: '',
 *      items: []
 * }
 * selectedFlavor: id
 * setSelectedFlavor: Function
 * */
function FlavorSelect(props) {
  return (
    <div>
      <RadioGroup
        value={props.selected}
        onChange={props.onChange}
        className="mt-2"
      >
        {props.flavorGroups?.map((group) => (
          <>
            <h3 className="text-sm text-gray-600">{group.name ?? "Flavor"}</h3>
            <Label className="sr-only">Choose a flavor</Label>
            <span>
              {group.items?.map((flavor) => (
                <Radio
                  key={flavor.item_name}
                  value={flavor}
                  className={({ active, checked }) =>
                    cn(
                      flavor.selectedColor,
                      active && checked ? "ring ring-offset-1" : "",
                      !active && checked ? "ring-2" : "",
                      "inline-flex cursor-pointer items-center justify-center rounded-full focus:outline-none"
                    )
                  }
                >
                  <Label className="text-center items-center rounded-full bg-gray-100 px-2.5 py-1.5 m-1 text-sm font-medium text-gray-600">
                    {flavor.item_name}
                  </Label>
                </Radio>
              ))}
            </span>
          </>
        ))}
      </RadioGroup>
    </div>
  );
}

export default FlavorSelect;
