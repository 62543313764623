import { useNavigate } from "react-router-dom";
import cn from "../utils/cn";
import img1 from "../resource/pro/BBE_0724-15.jpg";
import img2 from "../resource/pro/BBE_0724-16.jpg";
import img3 from "../resource/pro/BBE_0724-17.jpg";
import img5 from "../resource/pro/BBE_0724-18.jpg";
const categories = [
  {
    name: "Shop Energy",
    href: "/product/0",
    imageSrc: img1,
  },
  {
    name: "Shop LYTE",
    href: "/product/1",
    imageSrc: img2,
  },
  {
    name: "Shop Reload",
    href: "/product/2",
    imageSrc: img3,
  },

  {
    name: "Subscriptions",
    href: "/subscribe",
    imageSrc: img5,
  },
];

function CategoryList() {
  const navigate = useNavigate();
  return (
    <div className="bg-white">
      <div className="py-16 mx-auto sm:py-24 xl:mx-auto xl:max-w-7xl xl:px-8">
        <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0 pb-2">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">
            Categories
          </h2>
        </div>

        <div className="mt-4 flow-root">
          <div className="-my-2">
            <div className="relative box-content h-full overflow-x-auto py-2 xl:overflow-visible">
              <div className="w-fit h-full flex space-x-8 px-4 sm:px-6 lg:px-8 xl:relative xl:grid xl:grid-cols-4 xl:gap-x-8 xl:space-x-0 xl:px-0">
                {categories.map((category) => (
                  <button
                    onClick={() => {
                      if (!category.disabled) {
                        navigate(category.href);
                      }
                    }}
                    key={category.name}
                    className={cn(
                      "relative flex h-80 w-56 flex-col overflow-hidden rounded-lg p-6 xl:w-auto hover:opacity-75 text-black"
                    )}
                  >
                    <span aria-hidden="true" className="absolute inset-0">
                      <img
                        alt=""
                        src={category.imageSrc}
                        className={cn(
                          "h-full w-full object-cover object-center"
                        )}
                      />
                    </span>
                    <span
                      aria-hidden="true"
                      className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                    />
                    <span
                      className={cn(
                        "relative text-center w-full text-xl font-bold text-inherit mt-6 lg:mt-2"
                      )}
                    >
                      {category.name}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryList;
