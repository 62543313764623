import * as React from "react";
import { useParams } from "react-router-dom";
import Hero from "./hero";
import CardRadio from "./card_radio";
import FlavorSelect from "./flavor_select";
function SubscriptionPlan() {
  const { id } = useParams();
  return (
    <div className="">
      <Hero />
      <CardRadio />
      <FlavorSelect />
    </div>
  );
}
export default SubscriptionPlan;
