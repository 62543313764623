import { TrashIcon } from "@heroicons/react/20/solid";
import { PlusIcon, MinusIcon } from "@heroicons/react/24/outline";
import {
  get_promo_code,
  get_checkout,
  fetch_image,
  add_to_cart,
  update_line_item,
  delete_line_item,
  post_order_information,
} from "../api";
import { useQuery, useQueryClient } from "@tanstack/react-query";

function OrderSummary(props) {
  const queryClient = useQueryClient();
  const count_change = async (e, id, c) => {
    if (c && c > 0) {
      await update_line_item(id, c);
      await queryClient.invalidateQueries({
        queryKey: ["checkout"],
      });
    } else if (c > 0) {
      await update_line_item(id, c);
      await queryClient.invalidateQueries({
        queryKey: ["checkout"],
      });
    }
  };
  return (
    <section
      aria-labelledby="summary-heading"
      className="bg-gray-50 px-4 pb-10 pt-16 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16"
    >
      <div className="mx-auto max-w-lg lg:max-w-none">
        <h2 className="text-lg font-medium text-gray-900">Order summary</h2>

        <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
          <h3 className="sr-only">Items in your cart</h3>
          <ul className="divide-y divide-gray-200">
            {props.items.map((product) => (
              <li key={product.id} className="flex px-4 py-6 sm:px-6">
                <div className="flex-shrink-0">
                  <img
                    src={fetch_image(product.item_image)}
                    alt={product.imageAlt}
                    className="w-20 rounded-md"
                  />
                </div>

                <div className="ml-6 flex flex-1 flex-col">
                  <div className="flex">
                    <div className="min-w-0 flex-1">
                      <h4 className="text-sm">
                        <a
                          href={product.href}
                          className="font-medium text-gray-700 hover:text-gray-800"
                        >
                          {product.item.item_name}
                        </a>
                      </h4>
                      <p className="mt-1 text-sm text-gray-500">
                        {product.color}
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        {product.size}
                      </p>
                    </div>

                    <div className="ml-4 flow-root flex-shrink-0">
                      <button
                        type="button"
                        onClick={async () => {
                          await delete_line_item(product.line_item_id);
                          await queryClient.invalidateQueries({
                            queryKey: ["checkout"],
                          });
                        }}
                        className="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500"
                      >
                        <span className="sr-only">Remove</span>
                        <TrashIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-1 items-end justify-between pt-2">
                    <p className="mt-1 text-sm font-medium text-gray-900">
                      $
                      {(
                        (product.item.item_price * product.item_count) /
                        100
                      ).toFixed(2)}
                    </p>

                    <div className="ml-4">
                      <label htmlFor="quantity" className="sr-only">
                        Quantity
                      </label>
                      {product.item_count <= 10 ? (
                        <select
                          id="quantity"
                          name="quantity"
                          value={product.item_count}
                          onChange={async (e) => {
                            await update_line_item(
                              product.line_item_id,
                              e.target.value
                            );
                            await queryClient.invalidateQueries({
                              queryKey: ["checkout"],
                            });
                          }}
                          className="rounded-md border border-gray-300 text-left text-base font-medium text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                        >
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                          <option value={9}>9</option>
                          <option value={10}>10</option>
                          <option value={11}>10+</option>
                        </select>
                      ) : (
                        <div className="relative flex items-center max-w-[8rem] ml-4">
                          <button
                            type="button"
                            onClick={(e) =>
                              count_change(
                                e,
                                product.line_item_id,
                                product.item_count - 1
                              )
                            }
                            className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-2 h-full focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                          >
                            <MinusIcon className="w-5 h-5" />
                          </button>
                          <input
                            type="text"
                            id="quantity-input"
                            value={product.item_count}
                            disabled
                            className="bg-gray-50 border-x-0 border-gray-300 h-full text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            required
                          />
                          <button
                            type="button"
                            onClick={(e) =>
                              count_change(
                                e,
                                product.line_item_id,
                                product.item_count + 1
                              )
                            }
                            className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-2 h-full focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                          >
                            <PlusIcon className="w-5 h-5" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <dl className="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
            <div className="flex items-center justify-between">
              <dt className="text-sm">Subtotal</dt>
              <dd className="text-sm font-medium text-gray-900">
                ${(props.checkoutModel.sub_total / 100).toFixed(2)}
              </dd>
            </div>
            {props.checkoutModel.discount_type > 0 &&
              (props.checkoutModel.discount_type === 4 ? (
                <div className="flex items-center justify-center border-y py-2">
                  <dd className="text-sm font-medium text-green-700">
                    {props.checkoutModel.message}
                  </dd>
                </div>
              ) : (
                <>
                  <div className="flex items-center justify-between">
                    <dt className="text-sm text-green-700">Discount</dt>
                    <dd className="text-sm font-medium text-green-700">
                      ${(props.checkoutModel.discount / 100).toFixed(2)}
                    </dd>
                  </div>
                  <div className="flex items-center justify-between border-y py-2">
                    <dt className="text-sm"></dt>
                    <dd className="text-sm font-medium text-blue-600">
                      ${(props.checkoutModel.new_total / 100).toFixed(2)}
                    </dd>
                  </div>
                </>
              ))}
            <div className="flex items-center justify-between">
              <dt className="text-sm">Shipping</dt>
              <dd className="text-sm font-medium text-gray-900">
                ${(props.checkoutModel.shipping / 100).toFixed(2)}
              </dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-sm">Taxes</dt>
              <dd className="text-sm font-medium text-gray-900">
                ${(props.checkoutModel.tax / 100).toFixed(2)}
              </dd>
            </div>
            <div className="flex items-end justify-between border-t border-gray-200 pt-6">
              <div>
                <label
                  htmlFor="promo"
                  className="block text-sm font-medium text-gray-700"
                >
                  Promo / Athlete Code
                </label>
                <div className="mt-1">
                  <input
                    value={props.promo}
                    onChange={props.onPromoChange}
                    type="text"
                    id="promo"
                    name="promocode"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <button
                  onClick={props.handlePromoCodeApply}
                  type="button"
                  className="w-full rounded-md border border-transparent bg-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                  Apply
                </button>
              </div>
            </div>
            <div className="flex items-center justify-between border-t border-gray-200 pt-6">
              <dt className="text-base font-medium">Total</dt>
              <dd className="text-base font-medium text-gray-900">
                ${(props.checkoutModel.total / 100).toFixed(2)}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </section>
  );
}
export default OrderSummary;
